import { FirebaseAnalytics } from '@capacitor-firebase/analytics';
import type { UTMTracking } from './entity_utils';
import type { EntityType } from '$lib/server/entities/EntityType';
import settings_store from './indexeddb/settings_store';
import type { OriginalUploadPartnerType } from '$lib/server/entities/original_upload/OriginalUploadEntity';
import type { Permission } from '$lib/server/entities/permission/PermissionEntity';
import { Capacitor } from '@capacitor/core';
import { browser } from '$app/environment';
import env_client from '$lib/env/env_clientside';

class Analytics {

    async is_analytics_enabled(): Promise<boolean> {

        let settings_approved = await settings_store.get_setting('approved_analytics');
        let plugin_available = Capacitor.isPluginAvailable("FirebaseAnalytics");

        return (settings_approved?.value_boolean ?? false) && plugin_available;
    }

    private async log_event(name: string, params?: any) {

        if (!browser) {
            return;
        }

        if (env_client.is_dev()) {
            console.log("Logging event: ", name, "with params", JSON.stringify(params));
        }

        // alert(`About to log event -> ${name}`);
        // console.log("Analytics log event: ", name, JSON.stringify(params));
        let analytics_enabled = await this.is_analytics_enabled();
        if (!analytics_enabled) {
            return;
        }

        //console.log("Logging event: ", name, "with params", JSON.stringify(params));
        // alert(`Logged event -> ${name}`);
        return FirebaseAnalytics.logEvent({name, params});
    }

    async comment_submission(picture_count: number, entity_type: EntityType) {
        return this.log_event('new_comment', {picture_count, entity_type});
    }

    async discussion_submission(picture_count: number) {
        return this.log_event('new_discussion', {picture_count});
    }

    async user_content_submission(picture_count: number, is_update: boolean, entity_type: EntityType) {
        return this.log_event('set_user_content', {picture_count, is_update, entity_type});
    }

    async follow_user(follow_type: 'follow' | 'unfollow') {
        return this.log_event('follow', {follow_type});
    }

    async follow_user_search(results_count: number) {
        return this.log_event('follow_search', {results_count});
    }

    async next_page(type: string, page_number: number) {
        return this.log_event('next_page', {type, page_number});
    }

    async fetch_latest_page(type: string) {
        return this.log_event('fetch_latest_page', {type});
    }

    async sync_partner_activated(partner: OriginalUploadPartnerType) {
        return this.log_event('sync_partner_activated', {partner});
    }

    async animation_controls(action: 'start' | 'pause' | 'stop') {
        return this.log_event('animation_controls', {action});
    }

    async animation_speed(speed: number) {
        return this.log_event('animation_speed', {speed});
    }

    async tab_analysis(parent: 'track' | 'pool') {
        return this.log_event('tab_analysis', {parent});
    }

    async tab_splits(parent: 'track' | 'pool') {
        return this.log_event('tab_splits', {parent});
    }

    async tab_compare(parent: 'track' | 'pool') {
        return this.log_event('tab_compare', {parent});
    }

    async tab_comments(parent: 'track' | 'pool') {
        return this.log_event('tab_comments', {parent});
    }

    async tab_swims(parent: 'profile' = 'profile') {
        return this.log_event('tab_swims', {parent});
    }

    async tab_network(parent: 'profile' = 'profile') {
        return this.log_event('tab_network', {parent});
    }

    async tab_pictures(parent: 'profile' = 'profile') {
        return this.log_event('tab_pictures', {parent});
    }

    async tab_workouts(parent: 'profile' = 'profile') {
        return this.log_event('tab_workouts', {parent});
    }

    async tab(parent: 'track' | 'pool', tab: any) {
        return this.log_event('tab', {parent, tab});
    }

    async subtab(parent: 'track' | 'pool', subtab: any) {
        return this.log_event('subtab', {parent, subtab});
    }

    async map_controls(action: 'fly_to_marker' | 'full_screen') {
        return this.log_event('map_controls', action);
    }

    async slideout_details() {
        return this.log_event('slideout_details');
    }

    async privacy_setting(entity_type: EntityType, permission: Permission) {
        return this.log_event('privacy_settings', {entity_type, permission});
    }

    async track_synchronize_start() {
        return this.log_event('track_synchronize_start');
    }

    async track_outline(track_view_type: number, stretch_length: number) {
        return this.log_event('track_outline', {track_view_type, stretch_length});
    }

    async track_marker_visible(visible: boolean) {
        return this.log_event('track_marker_visible', {visible});
    }

    async compare_add(entity_type: 'track' | 'pool') {
        return this.log_event('compare_add', {entity_type});
    }

    async group_new() {
        return this.log_event('group_new');
    }

    async group_update() {
        return this.log_event('group_update');
    }

    async group_share(group_uuid: string, active: boolean) {
        return this.log_event('group_entity_share', {group_uuid, active});
    }

    async s3_upload_failure(user_uuid: string, 
                            entity_uuid: string, 
                            entity_type: EntityType, 
                            s3_resource_type: 'thumbnail_svg' | 'thumbnail_png' | 'json_data') {

        return this.log_event('s3_upload_failure', {user_uuid, entity_uuid, entity_type, s3_resource_type});
    }

    async static_map_failure(user_uuid: string, track_uuid: string, status: number) {
        return this.log_event('static_map_failure', {user_uuid, track_uuid, status});
    }

    async page_view(page_name: string, tracking?: UTMTracking) {

        let analytics_enabled = await this.is_analytics_enabled();
        if (!analytics_enabled) {
            return;
        }

        // alert(`Logging screen view: ${page_name}`);
        let utm_source = tracking?.utm_source;
        let utm_medium = tracking?.utm_medium;
        let utm_campaign = tracking?.utm_campaign;

        if (utm_source || utm_medium || utm_campaign) {

            await this.log_event('campaign_details', {
                source: utm_source,
                medium: utm_medium,
                campaign: utm_campaign
            });
        }

        return FirebaseAnalytics.setCurrentScreen({screenName: page_name, screenClassOverride: 'Capacitor Web View'});
    }
}

const analytics = new Analytics();
export default analytics;